
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Product } from "@/models/product.interface";
import { Navigation } from "@/mixins/navigation";
import { Category } from "@/models/category.interface";
import ProductAmount from "@/components/Layout/Client/ProductAmount.vue";
import CategoryBreadcrums from "@/components/Layout/Common/CategoryBreadcrums.vue";
import WishlistButton from "@/components/Layout/Client/WishlistButton.vue";
import { UserValidations } from "@/mixins/user-validations";
@Component({
  components: { ProductAmount, CategoryBreadcrums, WishlistButton },
})
export default class ProductCard extends mixins(Navigation, UserValidations) {
  @Prop({ required: true }) product!: any;
  @Prop({ required: false, default: false }) isWishlist!: boolean;
  @Prop({ required: true }) categories!: { all: Category[]; tree: Category[] };

  private goToProductDetail(product: Product) {
    this.navigate("/catalogue/product/investment/" + product.id + "/detail");
  }

  private updateWishlist() {
    this.$emit("updateWishlist");
  }
}
